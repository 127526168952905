<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg bg-white rounded-lg flex items-center p-5">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-5">
                    <label class="text-gray-400">
                        提領人 手機
                        <div class="flex">
                            <div class="phone-select flex-1">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div class="flex-1">
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-5">
                    <label class="text-gray-400">
                        <div>申請日期 範圍</div>
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-2 flex items-end justify-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px]" @click="filterSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px]" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div v-permission="['update']" class="action-bar bg-white rounded-lg mb-10 flex items-center px-5 justify-between">
            <el-checkbox :value="datas.length === choiceDatas.length && choiceDatas.length > 0" @change="choiceAll">全選此頁資料</el-checkbox>
            <div class="flex items-center">
                <button class="orange-btn-outline-800 md:text-sm text-xs h-[30px] mr-2" @click.prevent="downloadCsv">匯出成 TXT</button>
                <div v-if="choiceDatas.length > 0" class="choose-number">已選擇 {{ choiceDatas.length }} 筆提領申請</div>
                <button :disabled="choiceDatas.length == 0" class="orange-btn-outline-800 md:text-sm text-xs h-[30px]" @click="moveToFinshed">
                    移至撥款完成
                </button>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <OrderTable :datas="datas" :choiceDatas.sync="choiceDatas" />
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
        <!-- 匯出TXT dialog -->
        <PopupInputDate v-model="showDialog" :param="remittanceDateDialogParam" @confirm="exportTxt" />
    </div>
</template>

<script>
import fileDownload from "js-file-download";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// 導入表格
import OrderTable from "./components/Table.vue";
// 搜尋條件預設資料
const defaultFilterOpts = {
    phone_country: "886",
    status: [0, 1],
    reason: "CHARGE_OFF",
    created_date_start: "",
    created_date_end: "",
};
// 匯出資料時 選擇撥款日期套件
import PopupInputDate from "@/components/popup/PopupInputDate.vue";

export default {
    name: "SubmitApplication",
    components: {
        LoadingComponent,
        MyPagination,
        OrderTable,
        PopupInputDate,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            return {
                ...this.filterOpts,
                phone: phone,
                limit: this.paginationData.limit,
                page: this.currentPage,
                phone_country: "886",
            };
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: true,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 0,
            filterOpts: { ...defaultFilterOpts },
            // 時間範圍
            dateInputs: [],
            // 選中的訂單
            choiceDatas: [],
            // 預計撥款日期選擇彈窗資料
            remittanceDateDialogParam: {
                title: "匯出成 TXT",
                message: "請輸入預計匯款日期",
            },
            // 顯示下載匯出資料選擇 預計撥款日期彈窗
            showDialog: false,
        };
    },
    methods: {
        // 功能相關
        downloadCsv() {
            this.showDialog = true;
        },
        // 列表搜尋,分頁相關
        async getList() {
            this.loading = true;
            this.choiceDatas = [];
            try {
                const { data } = await this.$api.GetBrokerWaitRemittancesApi(this.filterData);
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            } finally {
                this.loading = false;
            }
        },
        /**
         * 搜尋
         */
        filterSearch() {
            this.currentPage = 1;
            this.getList();
        },
        /**
         * 重置
         */
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        /**
         * 每頁筆數顯示切換
         */
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        /**
         * 分頁切換
         */
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 選擇相關
        choiceAll(val) {
            if (val) {
                this.datas.forEach((item) => {
                    const id = item.id;
                    if (!this.choiceDatas.includes(id)) {
                        this.choiceDatas.push(id);
                    }
                });
            } else {
                this.choiceDatas = [];
            }
        },

        // 移至撥款完成
        moveToFinshed() {
            const opts = {
                title: "移至撥款完成",
                message: "是否將所選資料移至撥款完成狀態",
            };
            this.$pop.popConfirm(opts).then(
                () => {
                    //確認
                    this.changeStatusSelects();
                },
                () => {
                    //取消
                    console.log("cancel");
                }
            );
        },
        // 更新狀態
        async changeStatusSelects(status = 3) {
            const request = {
                ids: this.choiceDatas,
                status,
            };
            try {
                await this.$api.UpdateBrokerMoreRemittancesApi(request);
                this.$message({
                    type: "success",
                    message: "更新成功",
                });
                this.getList();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "更新失敗",
                });
            }
        },
        /**
         * 確認匯款資料是否有異常
         * @parma { type Date(日期格式) } 預計撥款日期
         */
        async checkRemittances(date) {
            const request = {
                ids: this.choiceDatas,
                date: date,
            };
            try {
                const { data } = await this.$api.CheckBrokerMoreRemittancesApi(request);
                return {
                    passed: [...data.passed],
                    failed: [...data.failed],
                };
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "匯出資料失敗",
                });
            }
        },
        /**
         * 匯出TXT
         * @parma { type Date(日期格式) } 預計撥款日期
         */
        async exportTxt(date) {
            const { passed, failed } = await this.checkRemittances(date);
            if (passed.length === 0 && failed.length > 0) {
                alert(`有${failed.length}筆資料資訊異常，已移至異常處理`);
                this.getList();
                return;
            }
            try {
                const { data } = await this.$api.ExportBrokerRemittancesApi({ ids: passed, date });
                fileDownload(data, `${this.$moment().format("YYYYMMDD")}.txt`);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "匯出資料失敗",
                });
            }
            if (failed.length > 0) {
                alert(`有${failed.length}筆資料資訊異常，已移至異常處理`);
                this.getList();
            }
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_date_start = "";
                this.filterOpts.created_date_end = "";
                return;
            }
            this.filterOpts.created_date_start = val[0];
            this.filterOpts.created_date_end = val[1];
        },
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            // 判斷有輸入值為 0 開頭時 捨棄 0 因為國碼搜尋不用 0 開頭
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
    mounted() {
        this.getList();
    },
    activated() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.choose-number {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    margin-right: 30px;
}

.phone-select {
    width: 150px;
    margin-right: 5px;
}

.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.checkbox-th {
    min-width: auto;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
